<template>
    <div class="setting_wpr">
        <div class="toggle_area">
            <h5>Edit Contact Details<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
            <button type="button" class="act_btn" @click="editContact = !editContact">{{editContact ? 'Hide' : 'Show'}}<i class="fas fa-angle-down"></i></button>
        </div>
        <!-- <label :for="contacts" class="switch_option capsule_btn">
            <h5>Edit Contact Details<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
            <input type="checkbox" :id="contacts" v-model="editContact" hidden>
            <div><span></span></div>
        </label> -->
        <div v-if="editContact" class="edit_section mb-5">
            <div class="section_title mb-4">Edit Contact</div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">First Name</label>
                    <div class="field_wpr">
                        <input type="text" placeholder="John" >
                    </div>
                </div>
                <div class="group_item">
                    <label class="input_label">Last Name</label>
                    <div class="field_wpr">
                        <input type="text" placeholder="Doe" >
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">E-mail</label>
                    <div class="field_wpr has_prefix">
                        <input type="text" placeholder="john.doe@onboardme.io" >
                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Gender</label>
                    <div class="field_wpr">
                        <Multiselect v-model="gender.value" v-bind="gender" placeholder="Select one"></Multiselect>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Mobile Number</label>
                    <div class="field_wpr">
                        <input type="text" v-model="phone" >
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Tabs</label>
                    <div class="field_wpr">
                        <Multiselect v-model="tabs.value" v-bind="tabs" placeholder="Select one"></Multiselect>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Temperature</label>
                    <div class="field_wpr">
                        <Multiselect v-model="temp.value" v-bind="temp" placeholder="Select one"></Multiselect>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Last Action</label>
                    <div class="field_wpr">
                        <Multiselect v-model="lastAction.value" v-bind="lastAction" placeholder="Select one"></Multiselect>
                    </div>
                </div>
            </div>
            <div v-if="moreOpt">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Business Name</label>
                        <div class="field_wpr has_prefix">
                            <input type="text" placeholder="Business Name Here.." >
                            <span class="prefix"><i class="fas fa-briefcase"></i></span>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Business Number</label>
                        <div class="field_wpr">
                            <input type="text" v-model="phone" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Address</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Address Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">City</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter City Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">State</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter State Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Zip Code</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter Zip Code Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Country</label>
                        <div class="field_wpr">
                            <Multiselect v-model="country.value" v-bind="country" placeholder="Select one"></Multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Timezone</label>
                        <div class="field_wpr">
                            <Multiselect v-model="timezone.value" v-bind="timezone" placeholder="Select one"></Multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Date of Birth</label>
                        <div class="field_wpr">
                            <datepicker v-model="date" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Source</label>
                        <div class="field_wpr">
                            <Multiselect v-model="sources.value" v-bind="sources" placeholder="Select one"></Multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Facebook Profile</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter Facebook Link Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Twitter Page</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter Twitter Page Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Linkedin Page</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter Linkedin Page Here.." >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Youtube Page</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Enter Youtube Page Here.." >
                        </div>
                    </div>
                </div>
            </div>
            <div class="action_wpr mt-4">
                <a class="more_btn" @click="moreOpt = !moreOpt"><i :class="!moreOpt ?'fas fa-plus-circle' : 'fas fa-minus-circle'"></i> {{!moreOpt ? 'More' : 'Less'}}..</a>
                <button class="btn cancel_btn" @click="editNote = false">Cancel</button>
                <button class="btn save_btn">Save</button>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Member ID <a class="option" @click="createID = !createID">Create Custom ID</a></label>
                <div class="field_wpr has_suffix">
                    <input type="text" value="WildCat777!" >
                    <span class="suffix">Resend</span>
                </div>
            </div>
        </div>
        <div v-if="createID" class="form_grp">
            <div class="group_item">
                <label class="input_label">New Member ID</label>
                <div class="field_wpr has_suffix">
                    <input type="text" placeholder="WildCat777!" >
                    <span class="suffix">Create</span>
                </div>
            </div>
        </div>
        <div class="text_info">
            The email <span>joedoe@example.com</span> has been reported as invalid. Please click edit and update to a valid email or verify it is correct by clicking the check mark.
        </div>
        <label for="email" class="switch_option capsule_btn">
            <h5>Ok to send Email<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
            <input type="checkbox" id="email" hidden>
            <div><span></span></div>
        </label>
        <label for="sms" class="switch_option capsule_btn">
            <h5>Ok to send SMS<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
            <input type="checkbox" id="sms" hidden>
            <div><span></span></div>
        </label>
        <label for="birthday" class="switch_option capsule_btn">
            <h5>Celebrate Birthday<span>Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent ut tellus magna.</span></h5>
            <input type="checkbox" id="birthday" hidden>
            <div><span></span></div>
        </label>
        <label for="broadcast" class="switch_option capsule_btn">
            <h5>Ok to send Broadcast<span>Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent ut tellus magna.</span></h5>
            <input type="checkbox" id="broadcast" hidden>
            <div><span></span></div>
        </label>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
    export default {
        name: "ToolSettings",
        components:{
            Multiselect
        },
        data(){
            return{
                editContact: true,
                moreOpt: false,
                createID: false,
                date: '',
                gender: {
                    mode: 'single',
                    value: ['Male'],
                    options: [
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' }
                    ],
                },
                tabs: {
                    mode: 'single',
                    value: ['Appt Booked'],
                    options: [
                        { value: 'New Leads', label: 'New Leads' },
                        { value: 'Made Contact', label: 'Made Contact' },
                        { value: 'Appt Booked', label: 'Appt Booked' },
                        { value: 'Appt Show', label: 'Appt Show' },
                        { value: 'Appt No Show', label: 'Appt No Show' },
                        { value: 'Active Trial', label: 'Active Trial' },
                        { value: 'Inactve Trial', label: 'Inactve Trial' },
                        { value: 'Active Clients', label: 'Active Clients' },
                        { value: 'Inactive Clients', label: 'Inactive Clients' },
                        { value: 'Active Staff', label: 'Active Staff' },
                        { value: 'Inactive Staff', label: 'Inactive Staff' },
                        { value: 'Vendors', label: 'Vendors' },
                        { value: 'Lost', label: 'Lost' }
                    ],
                    createTag: true
                },
                temp: {
                    mode: 'single',
                    value: ['Cold'],
                    options: [
                        { value: 'Cold', label: 'Cold' },
                        { value: 'Warm', label: 'Warm' },
                        { value: 'Hot', label: 'Hot' },
                    ],
                },
                lastAction: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Became A Lead', label: 'Became A Lead' },
                        { value: 'Member ID Created', label: 'Member ID Created' },
                        { value: 'Became A Lead: Offers', label: 'Became A Lead: Offers' },
                        { value: 'Became A Lead: Fit Funnels', label: 'Became A Lead: Fit Funnels' },
                        { value: 'Became A Staff Lead: Offers', label: 'Became A Staff Lead: Offers' },
                        { value: 'Email Outreach', label: 'Email Outreach' },
                        { value: 'SMS Outreach', label: 'SMS Outreach' },
                        { value: 'Phone Outreach', label: 'Phone Outreach' },
                        { value: 'Email Conversation', label: 'Email Conversation' },
                        { value: 'SMS Conversation', label: 'SMS Conversation' },
                        { value: 'Phone Conversation', label: 'Phone Conversation' },
                        { value: 'Pre-qualifiaction Sent', label: 'Pre-qualifiaction Sent' },
                        { value: 'Pre-qualifiaction Completed', label: 'Pre-qualifiaction Completed' },
                        { value: 'Consult Booked', label: 'Consult Booked' },
                        { value: 'Consult Completed', label: 'Consult Completed' },
                        { value: 'Consult Not Completed', label: 'Consult Not Completed' },
                        { value: 'No Sale', label: 'No Sale' },
                        { value: 'Trial Started', label: 'Trial Started' },
                        { value: 'Commitment Started', label: 'Commitment Started' },
                        { value: 'Became Inactive', label: 'Became Inactive' },
                        { value: 'Add An Option', label: 'Add An Option' },
                    ],
                    searchable: true
                },
                country: {
                    mode: 'single',
                    value: ['Israel'],
                    options: [
                        { value: 'Israel', label: 'Israel(+972)' },
                        { value: 'Afghanistan', label: 'Afghanistan(+93)' },
                        { value: 'Albania', label: 'Albania(+355)' },
                        { value: 'Algeria', label: 'Algeria(+213)' },
                    ],
                    searchable: true
                },
                timezone:{
                    mode: 'single',
                    value: ['gmt'],
                    options: [
                        { value: 'gmt', label: 'GMT +00:00 Africa/Accra' },
                        { value: 'cat', label: 'CAT +02:00 Africa/Blantyre' },
                        { value: 'cet', label: 'CET +01:00 Africa/Algiers' },
                        { value: 'eat', label: 'EAT +03:00 Africa/Addis_Ababa'},
                        { value: 'eet', label: 'EET +02:00 Africa/Cairo'},
                        { value: 'wat', label: 'WAT +01:00 Africa/Bangui'}
                    ],
                    searchable: true
                },
                sources: {
                    mode: 'single',
                    value: ['Referral'],
                    options: [
                        { value: 'Referral', label: 'Referral' },
                        { value: 'Internet', label: 'Internet' },
                        { value: 'Event', label: 'Event' },
                        { value: 'Daily Deal', label: 'Daily Deal' },
                        { value: 'Direct Mail', label: 'Direct Mail' },
                        { value: 'Networking Group', label: 'Networking Group' },
                        { value: 'JV Partner', label: 'JV Partner' },
                        { value: 'OM Offers', label: 'OM Offers' },
                        { value: 'Member ID', label: 'Member ID' },
                        { value: 'Facebook Ads', label: 'Facebook Ads' },
                        { value: 'Others', label: 'Others' }
                    ],
                },
            }
        }
    }
</script>